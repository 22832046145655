import './../App.css';
import { useState } from 'react';
import { AccountSettings } from '@aws-amplify/ui-react';
//import '@aws-amplify/ui-react/styles.css';

import { Alert, Box, Paper, Stack} from '@mui/material';

export function ChangePassword() {
    const [alertArea, setAlertArea] = useState(null);
    const handleSuccess = () => {
        setAlertArea((
            <Box sx={{paddingBottom: "10px"}}>
            <Alert severity='success'>Success!</Alert>
            </Box>
        ))
    }

    const handleError = (error) => {
        setAlertArea((
            <Box sx={{paddingBottom: "10px"}}>
            <Alert severity='error' sx={{padding: "10px"}}>{error.message}</Alert>
            </Box>
        ))
    }

    const components = {
        ErrorMessage: ({ children }) => ("")
      };

    return (
        <Box p={10}>
        <Paper elevation={24} square={false} sx={{minWidth: '400px', maxWidth: '30%', mx: 'auto', "background-image": "var(--mui-palette-primary-main)", color: "var(--brand-main)"}}>  
            <h2>Change Password</h2>
            <Box p={5}>
                    {alertArea}
                    <AccountSettings.ChangePassword components={components} onSuccess={handleSuccess} onError={handleError} />          
            </Box>
        </Paper>
        </Box>
    );
}

export default ChangePassword;