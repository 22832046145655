
export const AUTH_CONFIG = {
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        'REQUIRES_LOWERCASE',
        'REQUIRES_NUMBERS',
        'REQUIRES_SYMBOLS',
        'REQUIRES_UPPERCASE'
      ]
    },
    Auth: {
      region: process.env.REACT_APP_AUTH_REGION,
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AUTH_APP_CLIENT_ID,
      mandatorySignIn: process.env.REACT_APP_AUTH_MANDATORY_SIGNIN,
      authenticationFlowType: process.env.REACT_APP_AUTH_AUTH_FLOW_TYPE,
      oauth: {
        domain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN,
        scope: process.env.REACT_APP_AUTH_OAUTH_SCOPE_LIST.split(','),
        redirectSignIn: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGNOUT,
        clientId: process.env.REACT_APP_AUTH_APP_CLIENT_ID,
        responseType: process.env.REACT_APP_AUTH_OAUTH_RESPONSE_TYPE
      }
    }, 
    Analytics: {
        disabled: true
    }
};
