import { load } from 'protobufjs';
import { inflate } from 'pako';

const TimeSeriesProtofile = require("./timeseries.proto");
const TimeSeriesRoot = await load(TimeSeriesProtofile);
const TimeSeriesResponse = TimeSeriesRoot.lookupType("timeseries.TimeSeriesResponse");

export function decodeTimeSeriesApiResponse(b64encoding) {
    const zipbuf = new Uint8Array(atob(b64encoding).split("").map(function(c) {
        return c.charCodeAt(0); 
    }));

    try {
        const protobuf = inflate(zipbuf);
        const tsRespMsg = TimeSeriesResponse.decode(protobuf);
        const tsResp = TimeSeriesResponse.toObject(tsRespMsg, {defaults: true});
        console.log("Decoded API response");
        //console.log(tsResp); //@@@TODO comment out for deployment
        return tsResp;
    } catch (err) {
        console.log(err);
        return []
    }
}