import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import CssBaseline from '@mui/material/CssBaseline';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

import {
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider,  
} from '@mui/material/styles';

import { Amplify } from '@aws-amplify/core';
// import '@aws-amplify/ui-react/styles.css';

import { AUTH_CONFIG } from './auth_config';
// import { colors } from '@mui/material';


import { ampli } from './ampli/index.ts';
ampli.load(
  { 
    client: { 
      apiKey: process.env.REACT_APP_AMPLI_API_KEY, 
      configuration: {defaultTracking: true}
    } 
  }
);

Amplify.configure(AUTH_CONFIG)


// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     background: {

//     },
//   },
//   typography: {
//     fontFamily: 'lato',
//     fontWeightRegular: 400
//   }
// });

// console.log(darkTheme);

const theme = extendTheme({
  typography: {
    fontFamily: 'figtree',
    fontWeightRegular: 400
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={darkTheme}> */}
    <CssVarsProvider theme={theme} defaultMode="dark" modeStorageKey="dark-mode-default">
      <CssBaseline enableColorScheme/>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <App />
      </CookiesProvider>
    </CssVarsProvider>
    {/* </ThemeProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
