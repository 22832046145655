import './../App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { Alert, Box, Paper, Stack, Button } from '@mui/material';

export function UserProfile() {
    const [user, setUser] = useState(null);
    const [messageArea, setMessageArea] = useState(null);

    // Runs once after initial render
    useEffect(
        () => {
            Auth.currentAuthenticatedUser({
                bypassCache: true
              })
            .then(data => setUser(data))
            .catch(error => console.log(error))
        },  
        []
    );

    if (user === null) {
        return null;
    } 

    const onSubmit = (data) => {
        // console.log(data);
        Auth.updateUserAttributes(user, {
            'given_name': data['first_name'],
            'family_name': data['last_name'],
            'custom:company': data['company_name']
        })
        .then(setMessageArea((
            <Alert severity="success">Success!</Alert>
        )))
        .catch(error => console.log(error))
    }

    return (
        <div name="UserAttributesPane">            
            <Box p={10}>                
                <Paper elevation={24} square={false} sx={{minWidth: '400px', maxWidth: '30%', mx: 'auto', "background-image": "var(--mui-palette-primary-main)", color: "var(--brand-main)"}}>                
                    <Box p={5}>                    
                        <FormContainer
                        defaultValues={{
                            email: user.attributes['email'], 
                            first_name: user.attributes['given_name'], 
                            last_name: user.attributes['family_name'], 
                            company_name: user.attributes['custom:company']
                        }}      
                        onSuccess={onSubmit}
                        >                            
                        <Stack spacing={3}>
                            <h2>User Profile</h2>
                            {messageArea}
                            <TextFieldElement name="email" type="email" label="E-mail" required disabled={true}/>
                            <TextFieldElement name="first_name" label="First Name" required 
                                validation={{
                                    maxLength: {value: 50, message: "Maximum length is 50."}, 
                                    minLength: {value: 2, message: "Minimum length is 2."}
                                }}
                            />
                            <TextFieldElement name="last_name" label="Last Name" required 
                                validation={{
                                    maxLength: {value: 50, message: "Maximum length is 50."}, 
                                    minLength: {value: 2, message: "Minimum length is 2."}
                                }}
                            />
                            <TextFieldElement name="company_name" label="Company Name"
                                validation={{
                                    maxLength: {value: 50, message: "Maximum length is 50."},                                     
                                }}
                            />            
                            <Button variant='contained' type='submit'sx={{"background-color": "var(--brand-main)",}}>Update</Button>
                        </Stack>
                        </FormContainer>
                    </Box>
                </Paper>
            </Box>
        </div>
    );
}

export default UserProfile;