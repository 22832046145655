import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export function ErrorBox() {
    return (
        <Stack alignItems="center" justifyContent="center" height="100%">
            <Alert icon={<SentimentVeryDissatisfiedIcon fontSize="large" />} severity='error' fontSize="large">
                <AlertTitle fontSize="large"><strong>Oops!</strong></AlertTitle>                
                <Typography fontSize="large">
                    Something went wrong while displaying this page.<br/>Our team has been alerted and is working on a fix!
                </Typography> 
            </Alert>
        </Stack> 
    )
}