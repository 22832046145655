import { load } from 'protobufjs';
import { inflate } from 'pako';

const TableSeriesProtofile = require("./tableseries.proto");
const TableSeriesRoot = await load(TableSeriesProtofile);
const TableSeriesResponse = TableSeriesRoot.lookupType("tableseries.TableSeriesResponse");

export function decodeTableSeriesApiResponse(b64encoding) {
    const zipbuf = new Uint8Array(atob(b64encoding).split("").map(function(c) {
        return c.charCodeAt(0); 
    }));

    try {
        const protobuf = inflate(zipbuf);
        const tsRespMsg = TableSeriesResponse.decode(protobuf);
        const tsResp = TableSeriesResponse.toObject(tsRespMsg, {defaults: true});
        console.log("Decoded Table API response");
        // console.log(tsResp); //@@@TODO comment out for deployment
        return tsResp;
    } catch (err) {
        console.log(err);
        return []
    }
}