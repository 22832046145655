import { createContext } from "react";

export const DateRangeContext = createContext();
export const SearchBarContext = createContext();
export const ChartExpandStateContext = createContext();
export const DisplayedCikContext = createContext();
export const SelectedDatapointContext = createContext();
export const SelectedTimeSeriesContext = createContext();
export const OnboardingWizardContext = createContext();

export const SelectedTableSeriesContext = createContext();
export const TableSeriesExpandStateContext = createContext();