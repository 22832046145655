const faq = [
    {
        "Section": "",
        "Questions": [
            {
                "Q": "Do you use Generative AI?",
                "A": "We do not use generative AIs or large language models (LLMs) to perform our time series extraction.  As a result, we do not suffer from the same kinds of hallucinations that may arise from those models.  Instead, we use our own proprietary neural language models, which we have trained specifically to perform time series extraction with high fidelity and faithfulness to the source texts."
            }, 
            {
                "Q": "What companies are included?",
                "A": <>During the Beta Program, we include KPIs from companies in the DJIA, S&P 500, S&P 400, and S&P 600.  You can see the list <a href="https://docs.google.com/document/d/1CU3OkgcYUykqbzEMMA7fXM32OkyAhWvPI1hW35U6pfA" target="_blank">here</a>. As the Beta progresses, we plan to expand the number of companies covered.</>
            },
            {
                "Q": "Which SEC Form Types are used as sources?  Do you merge time series from different Form Types?", 
                "A": "We extract time series from 10-K, 10-Q, and 8-K form types and their variants: 10-K, 10-K/A, 10-KT, 10-KT/A, 10-Q, 10-Q/A, 10-QT, 10-QT/A, 8-K, and 8-K/A.  Time series are extracted within each major form type independently.  For instance, we do not merge Q4 data points extracted from a 10-K with those extracted from a 10-Q."   
            },
            {
                "Q": "How far back does your data go?",
                "A": "Our Beta Program starts with KPIs extracted from approximately 10 years of historical filings, i.e., from January 1, 2014 to present.  As the Beta progress, we plan to extend our historical data as far back as possible."
            },
            {
                "Q": "Is your data extraction accurate?",
                "A": "Every time series we extract undergoes stringent quality-control checks before you even see it.  If our pipeline isn't confident about a time series or even a single datapoint within a time series it extracts, we won't show it to you."
            }, 
            {
                "Q": "Are your time series complete?", 
                "A": "A time series may be incomplete for several reasons.  In most cases, a company may have simply skipped reporting a particular KPI for a specific period.  Less frequently, our data quality checks may filter out a low-confidence data point from a time series."
            },             
            {
                "Q": "Do you extract KPI time series from tables?", 
                "A": "Extracting time series across tables is one of the highest priority items on our roadmap."
            },
            {
                "Q": "How often are your time series updated?", 
                "A": "We update our time series once a day, sometime after 10PM Eastern Time.  Real-time updating is on our roadmap."
            },
            {
                "Q": "How do you decide which KPIs to extract?",
                "A": "We've trained our neural language models to understand the language and concepts found in SEC filings, so our models are able to determine what is and what isn't a KPI with high confidence." 
            },
            {
                "Q": "How do you determine the date for each data point you extract?  How do you handle fiscal years?", 
                "A": "The time context for each data point is highly dependent on how it is reported within the text of the filing.  Our system takes into account the company's fiscal year, the filing date of the report, its reporting period, and any relevant time context from within the text, to determine the time context of a specific data point.  For companies with fiscal years that aren't aligned to the calendar year, we translate their fiscal time contexts into calendar year equivalents."
            },            
            {
                "Q": "It looks like some time series are duplicated.  Why?", 
                "A": "Some time series may appear to be duplicates because their titles are very similar; we alert you to these with an orange \"merging arrows\" icon.  In these cases, we encourage you to click a data point in the time series to understand it within the source filing's context. Where appropriate, you may wish to merge similar-looking time series using our \"Merge Time Series & Export\" feature."
            },
            {
                "Q": "It looks like some time series are split into two.  Why?", 
                "A": "In most cases, this occurs when a company has simply changed the way in which they report a KPI.  If you encounter these situations, we encourage you to select those time series should be merged and use our \"Merge Time Series & Export\" feature to join the time series together."
            }, 
            {
                "Q": "Why did the website and data change suddenly?",
                "A": "During the Beta Program we will be continuously improving deepKPI. As a result, you may notice updates to both the website and the available data."
            }
        ]
    }
];
export default faq;