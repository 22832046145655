
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router';
import { Box } from '@mui/material';
// import { styled } from "@mui/material/styles";
import { Authenticator, CheckboxField, Flex, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n, Auth } from 'aws-amplify';
// import { Hub } from 'aws-amplify';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import '../App.css';

import { ampli } from '../ampli/index.ts';

I18n.putVocabulariesForLanguage('en', {
  'Given Name': 'First Name',
  'Enter your Given Name': 'Enter your first name',
  'Family Name': 'Last Name',
  'Enter your Family Name': 'Enter your last name',
  'We Texted You': 'We E-mailed You',
  'Enter your Email': 'E-mail',
  'Enter your Password': 'Password',
  "PreSignUp failed with error Invalid Invitation Code.": "The invitation code you entered is invalid. Please check the code and try again. If you don't have a valid code, please join our waitlist with the code WAITLIST during signup.",
  "PreAuthentication failed with error You are still on a wait list!.": "Thanks for your interest! We've placed you on the deepKPI waitlist and will be in touch soon.",
  "PreAuthentication failed with error 'email'.": "We couldn't find an account with those credentials. Please try again.",
  "PostConfirmation failed with error Sorry you are on the waitlist!.": "Thanks for your interest! We've placed you on the deepKPI waitlist and will be in touch soon.",
});

// async function saveUserData(user) {

// }

export function Login({tab}) {
    const { route, user } = useAuthenticator((context) => [context.route, context.user]);
    const [isFirstLogin, setIsFirstLogin] = useState(false); 
    const location = useLocation();
    const navigate = useNavigate();

    // Set up listener for first (auto-)login after signup.  This will trigger a write to the user
    // database. 
    // useEffect(() => {
    //     Hub.listen('auth', (data) => {
    //         if (data['payload']['event'] === "confirmSignUp") {                
    //             setIsFirstLogin(true);
    //         }
    //     });
    // }, []);

    // If authenticated, then forward to appropriate location
    let from = location.state?.from?.pathname || '/app';
    useEffect(() => {
        if (route === 'authenticated') {
            // Write some data to the user database if this was the first login. 
            // if (isFirstLogin) {
            //     console.log("ping database:", user);
            //     saveUserData(user)
            // }
            ampli.identify(user.username);
            ampli.login();
            navigate(from, { replace: true });
        }
    }, [isFirstLogin, user, route, navigate, from]);

    const components = {
        SignUp: {
            FormFields() {
                const { validationErrors } = useAuthenticator();
                return (
                    <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        <Authenticator.SignUp.FormFields />

                        {/* Append & require Terms & Conditions field to sign up  */}
                        <Flex gap="0.25rem">
                            <CheckboxField
                                errorMessage={validationErrors.acknowledgement}
                                hasError={!!validationErrors.acknowledgement}
                                name="acknowledgement"
                                value="yes"
                                label="I agree with the Terms of Service"
                            /><a href="/static/tos.html" target="_blank"><OpenInNewIcon fontSize="small" sx={{marginTop: "3px", padding: "1px", color: "var(--mui-palette-primary-main)"}}/></a>
                        </Flex>
                    </>
                );
            }
        }
    }

    const services = {
        async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
                return {
                    acknowledgement: '',
                };
            }
        },
        async handleSignUp(formData) {
            let { username, password, attributes } = formData;
            ampli.createAccountButtonClick({"Company": attributes["custom:company"], "Invitation Code": attributes["custom:invitation_code"]});
            return Auth.signUp({
              username,
              password,
              attributes,
              autoSignIn: {
                enabled: true,
              },
            });
          },
    }

    const formFields = {
        signUp: {
            email: {
                placeholder: "Enter your e-mail address",
                isRequired: true,
                label: "E-mail"
            },
            password: {
                placeholder: "Enter your password",
                isRequired: true,
                label: "Password"
            },
            confirm_password: {
                placeholder: "Enter your password again",
                isRequired: true,
                label: "Confirm Password"
            },
            "custom:company": {
                placeholder: "Enter your company name",
                isRequired: false,
                label: "Company"
            },
            "custom:invitation_code": {
                placeholder: "Enter your invitation code",
                isRequired: true,
                label: "Invitation Code"
            }
        }
    }
    console.log(tab);


    return (             
        <Box>        
            <Box sx={{display: {xs: 'none', md: 'block', lg:'block'}, mt: "10vh"}}>            
                <Authenticator
                    initialState={tab}
                    formFields={formFields}
                    loginMechanisms={['email']}
                    signUpAttributes={['given_name', 'family_name']}
                    // socialProviders={['google']}
                    components={components}
                    services={services}
                    hideSignUp={false}
                ></Authenticator>
            </Box>   
            <Box sx={{display: {xs: 'block', md: 'none', lg:'none'}, mt: "10vh"}}>
            <Authenticator
                initialState={tab}
                formFields={formFields}
                loginMechanisms={['email']}
                signUpAttributes={['given_name', 'family_name']}
                //socialProviders={['google']}
                components={components}
                services={services}
                hideSignUp={false}            
            ></Authenticator>
            </Box>   
        </Box>
    );
}

export default Login; 

