import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import HelpIcon from '@mui/icons-material/Help';
import LoginIcon from '@mui/icons-material/Login';
import RevelataLogoSvg from './svg/deepKPI_by_revelata-logo.svg';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Stack } from '@mui/material';
import { ampli } from '../ampli/index.ts';


// const pages = ['Sign In'];

const menuEntries = {
  'Profile': '/profile', 
  'Change Password': '/changepassword'
}; 


export function TopAppBar() {
  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { route, signOut, user } = useAuthenticator((context) => [context.route, context.signOut, context.user])

  const [anchorElUser, setAnchorElUser] = useState(null);

  const [cookies, setCookie, removeCookie] = useCookies()

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserMenuPick = (e, menuEntry) => {
    // console.log(menuEntry);
    handleCloseUserMenu();
  };
  
  // Set the userMenuElement either "Sign In" or dropdown menu
  let userMenuElement = null; 
  let supportButton = null;
  let homeButton = null;
  let excelAddinButton = null;
  if ( route === 'idle') {
    return null;
  }
  const onClickSignInButton = (location) => {     
    ampli.loginButtonClick({ "Button Location": location});             
}
  
  if (route !== 'authenticated') {
    userMenuElement = (
      <>
        <Button  variant='outlined' href="/login" sx={{borderColor: "white", textTransform: 'none', borderRadius: 50,  my: 2, color: 'white', display: { xs: 'none', md: 'flex', lg:'flex'}}}  onClick={() => onClickSignInButton("Top App Bar")}>Sign in</Button>
        <IconButton  href="/login" sx={{ display: {xs:'flex', md: 'none', lg: 'none'}, marginRight: "10px"}} onClick={() => onClickSignInButton("Top App Bar Mobile")}>
          <LoginIcon htmlColor="var(--mui-palette-primary-main)" sx={{strokeWidth: 1}}/>
        </IconButton>
      </>
    )
  } else {

    // console.log(anchorElUser);
    supportButton = (
      <Button sx={{display: {xs: 'none', md: 'flex', lg:'flex'}, marginRight: "8px", color: 'white'}} component={RouterLink} to="/support">Support</Button>
    )
    homeButton = (
      <Button sx={{display: {xs: 'none', md: 'flex', lg:'flex'}, marginRight: "8px", color: 'white'}}  component={RouterLink} to="/app">Home</Button>
    )
    excelAddinButton = (
      <Button sx={{display: {xs: 'none', md: 'flex', lg:'flex'}, marginRight: "25px", color: 'white'}}  href="https://appsource.microsoft.com/en-us/product/office/WA200007695?tab=Overview">EXCEL ADD-IN</Button>
    )
    userMenuElement = ( 
      <Box sx={{ flexGrow: 0 }}>      
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar sx={{height: "32px", width:"32px"}} alt={user.attributes['given_name']}>{user.attributes['given_name'][0]}</Avatar>
        </IconButton>        
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {Object.keys(menuEntries).map((displayName) => (
            <MenuItem key={displayName} component={RouterLink} to={menuEntries[displayName]} onClick={(e) => {handleUserMenuPick(e, displayName)}}>
              <Typography textAlign="center">{displayName}</Typography>
            </MenuItem>
          ))}
          <MenuItem onClick={() => { handleCloseUserMenu(); signOut(); removeCookie("DEEPKPI_betaLoginModal") }}>Sign Out</MenuItem>
        </Menu> 
    </Box>);
  };

  // console.log(anchorElUser)

  return (
    <AppBar position="static" sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none'}}>
      <Container maxWidth="false" sx={{padding: 0}}>
        <Toolbar disableGutters sx={{padding: "5px", height: "50px"}}> 
          <Box sx={{pt: 4, display: { xs: 'none', md: 'flex', lg: 'flex' }}} component="a" href={route !== 'authenticated' ? "/" : "/app"}>
            <img src={RevelataLogoSvg} alt="Revelata Logo" height={80}/>
          </Box>
          <Box sx={{pt: 3, display: { xs: 'flex', md: 'none', lg: 'none' }}} component="a" href={route !== 'authenticated' ? "/" : "/app"}>
          <img src={RevelataLogoSvg} alt="Revelata Logo" height={60}/>
          </Box>
            <Typography 
                noWrap                
                sx={{
                  pl: 0.5,
                  pr: 0.5,
                  mt: -1,
                  display: { xs: 'flex', md: 'flex', lg: 'flex' },
                  fontFamily: 'Figtree',
                  // fontSize: "1.25rem",
                  fontSize: { xs: '12px', md: '15px' },
                  fontWeight: 700,
                  letterSpacing: '.1rem',
                  color: 'orange',
                  textDecoration: 'none',
                  border: '1px solid orange',
                  borderRadius: 1
                }}>
                  Beta
              </Typography> 
          {/* <Box sx={{flexDirection: "column", margin: 0, display: { xs: 'none', md: 'flex', lg: 'flex' }}}>
            <Typography
              variant="h3"
              noWrap
              component="a"
              href={route !== 'authenticated' ? "/" : "/app"}
              sx={{
                ml: 1,
                mr: 2, 
                mb: -0.75,             
                display: { xs: 'none', md: 'flex', lg: 'flex' },
                fontFamily: 'Figtree',
                fontWeight: 300,
                fontSize: "2.5rem",
                letterSpacing: '.2rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              deepKPI    
                <Typography variant="h6"
                noWrap
                sx={{
                  ml: 0.5,
                  display: { xs: 'none', md: 'flex', lg: 'flex' },
                  fontFamily: 'Figtree',
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  letterSpacing: '.1rem',
                  color: 'orange',
                  textDecoration: 'none',
                }}>
                  BETA
              </Typography>      
            </Typography>
            <Typography sx={{
                ml: 1.25,
                mr: 2,                   
                padding: 0,      
                display: { xs: 'none', md: 'flex', lg: 'flex' },
                fontFamily: 'Figtree',
                fontSize: '0.875rem',              
                fontWeight: 400,
                letterSpacing: '.575rem',
                color: 'var(--revelata-blue)',
                textDecoration: 'none',
              }}>
              by revelata
            </Typography>
          </Box> */}

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu> 
          </Box> */}

          {/* <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none', lg: 'none'}, flexDirection: "column", margin: '5px'}}>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Figtree',
              fontWeight: 400,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            deepKPI    
            <Typography               
              noWrap
              sx={{
                display: { xs: 'flex', md: 'none'},
                fontFamily: 'Figtree',
                fontSize: '0.5em',
                fontWeight: 400,
                letterSpacing: '.1rem',
                color: "orange",
                textDecoration: 'none',
              }}>
                BETA
            </Typography>     
          </Typography>
          <Typography sx={{              
              mr: 2,                   
              padding: 0,      
              display: { xs: 'flex', md: 'none', lg: 'none' },
              fontFamily: 'Figtree',
              fontSize: '0.7em',              
              fontWeight: 400,
              letterSpacing: '.4rem',
              color: 'var(--revelata-blue)',
              textDecoration: 'none',
            }}>
            by revelata
          </Typography>
          </Box> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'flex' } }}>
            {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))} */}
          </Box>

          
          <Stack direction="row" justifyContent="end">
            {homeButton}
            {supportButton}
            {excelAddinButton}
            {userMenuElement}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopAppBar;
