import { load } from 'protobufjs';
import pako from 'pako';

/* -- Datapoint Provenance API ------------------------------------------------------------------------- */

const DatapointProvenanceProtofile = require("./datapointProvenance.proto");
const DatapointProvenanceRoot = await load(DatapointProvenanceProtofile);
const DatapointProvenanceMsg = DatapointProvenanceRoot.lookupType("provenance.DatapointProvenanceMessage");

// export async function test(datapoint) {      
//     let message = DatapointProvenanceMsg.create(datapoint);
//     console.log(`message = ${JSON.stringify(message)}`);
      
//     let buffer = DatapointProvenanceMsg.encode(message).finish();
//     // console.log(`buffer = ${Array.prototype.toString.call(buffer)}`);
//     console.log(buffer);
//     console.log(Object.prototype.toString.call(buffer));
//     let b64enc = btoa(String.fromCharCode.apply(null, buffer));
//     console.log("b64:", b64enc);

//     let dec = new Uint8Array(atob(b64enc).split("").map(function(c) {
//                 return c.charCodeAt(0); 
//             }));
//     console.log(dec);

//     console.log(dec == buffer)

//     let decoded = DatapointProvenanceMsg.decode(dec);
//     console.log(decoded);
//     console.log(`decoded = ${JSON.stringify(decoded)}`);
// }

export function encodeDatapoint(dp) {
    const message = DatapointProvenanceMsg.create(dp);  
    const buffer = DatapointProvenanceMsg.encode(message).finish();
    const compressed = pako.deflate(buffer);
    const b64enc = btoa(String.fromCharCode.apply(null, compressed)).replace(/\+/g, '-').replace(/\//g, '_');  // make it URL-safe    
    return b64enc;
}

export function decodeDatapoint(b64encoding) {
    const buf = new Uint8Array(atob(b64encoding.replace(/-/g, '+').replace(/_/g, '/')).split("").map(function(c) {
        return c.charCodeAt(0); 
    }));
    const decompressed = pako.inflate(buf);
    const decoded = DatapointProvenanceMsg.decode(decompressed);
    return decoded;
}


