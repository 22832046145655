import { parse, isValid } from 'date-fns';
import enUs from 'date-fns/locale/en-US';

export function isValidDate(datestr) {    
    // console.log("isValidDate", datestr, isValid(parse(datestr, 'P', new Date(), { locale: enUs })));
    return isValid(parse(datestr, 'P', new Date(), { locale: enUs }));
}


export function longestCommonSubstring(src, tgt) { 
    let n = src.length; 
    let m = tgt.length; 
  
    let lcs = []; 
    for (let i = 0; i <= n; i++) { 
        lcs[i] = []; 
        for (let j = 0; j <= m; j++) { 
            lcs[i][j] = 0; 
        } 
    } 
  
    let lcsStart = 0;
    let lcsEnd = 0; 
    let max = 0; 
    for (let i = 0; i < n; i++) { 
        for (let j = 0; j < m; j++) { 
            if (src[i] === tgt[j]) { 
                lcs[i + 1][j + 1] = lcs[i][j] + 1; 
                if (lcs[i + 1][j + 1] > max) { 
                    max = lcs[i + 1][j + 1]; 
                    lcsStart = i - max + 1
                    lcsEnd = i + 1; 
                } 
            } 
        } 
    } 
  
    return [lcsStart, lcsEnd]; 
}

export function longestPrefixInString(src, tgt) {
    let srcMatchIdx = -1
    let i = 0;
    for (i = tgt.length; i >= 0; i--) {
        // console.log(tgt.substring(0, i));
        srcMatchIdx = src.indexOf(tgt.substring(0, i));
        if (srcMatchIdx > -1) {
            return [srcMatchIdx, i];
        }
    }
    return [srcMatchIdx, i];
}