import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Stack, Typography} from '@mui/material';
import './../App.css';
import faq from './FaqData.js'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import React from 'react';
// import { useState, useEffect } from 'react';

export default function Support() {
    return (
        <Container sx={{border: 0}}>            
            <Typography variant="h3" sx={{padding: 5, border: 0, fontWeight: 200}}>Support</Typography>
            <Stack direction="row" sx={{border: 0}} alignItems="center" justifyContent="center">                
                <Box sx={{borderRadius: "16px"}}>
                    <Box p={5}>
                    <Typography variant="h6" textAlign="center">
                    For the duration of our Beta Program, please e-mail us at: 
                    </Typography>
                    <br/>
                    <Typography variant="h5" textAlign="center">
                    <a href="mailto:support@revelata.com" >support@revelata.com</a>
                    </Typography>
                    </Box>
                </Box>
            </Stack>
            <Typography variant="h3" sx={{pt: 10, pl: 5, pr: 5, pb: 5, border: 0, fontWeight: 200}}>FAQ</Typography>
            <Stack direction="row" sx={{border: 0, pb: 5}}> 
            {
                faq.map(
                    (section) => {
                        return (
                            <Box sx={{pl: 5, pr: 5}}>
                            {section['Section'].length > 0 ? <Typography variant="h4">{section['Section']}</Typography> : null}
                            {
                                section['Questions'].map(
                                    (question) => {
                                        return (
                                            <Accordion sx={{border: 0, mb: 1}} square={false}>
                                                <AccordionSummary sx={{border: 0}} expandIcon={<ExpandMoreIcon /> }>
                                                    <Typography variant="h5">{question['Q']}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{borderTop: 1, borderColor: "var(--mui-palette-background-default)"}}>
                                                <Typography variant="h6" sx={{color: "var(--mui-palette-primary-main)"}}>{question['A']}</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    }
                                )
                            }

                            </Box>
                        )
                    }
                )
            }
            </Stack>
        </Container>
    );
}