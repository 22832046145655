import { Typography } from '@mui/material';

export function Highlighter({ text, keyword, sx }) {
  const startIndex = text.toLowerCase().indexOf(keyword.toLowerCase());
  const endIndex = startIndex + keyword.length;
  if (startIndex === -1) {
    // Keyword not found
    return <Typography sx={{ ...sx }}>{text}</Typography>;
  }
  const beforeKeyword = text.substring(0, startIndex);
  const boldedKeyword = <span key="keyword" style={{ fontWeight: 'bold', textDecoration: 'underline', backgroundColor: 'orange', color: 'black' }}>{text.substring(startIndex, endIndex)}</span>;
  const afterKeyword = text.substring(endIndex);

  return (
    <Typography sx={{ ...sx }}>
      {beforeKeyword}
      {boldedKeyword}
      {afterKeyword}
    </Typography>
  );
}
