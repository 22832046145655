import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';


export function RequireAuth({ children }) {
  const location = useLocation();
  const { route, user } = useAuthenticator((context) => [context.route, context.user]);

  // console.log("RequireAuth", location);
  // console.log("RequireAuth", route);

  if (route === 'idle') {
    return null; 
  }

  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // console.log(user);
  // if (user && isValidInvitationCode(user.attributes['sub'], user.attributes['customer:invitation_code'])) {
  //   return children;
  // }
  return children; 
}

export default RequireAuth;