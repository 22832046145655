
export const API_URL = process.env.REACT_APP_API_BASE_URL

export function makePostRequest(url, accessToken, data) {
    const headers = new Headers();
    //headers.append('Access-Control-Allow-Origin', process.env.REACT_APP_BASE_URL);
    headers.append('Content-Type', 'application/json');

    if (accessToken) {
        headers.append('Authorization', accessToken);
    }

    //console.log('data', data);
    const body = JSON.stringify(data);

    // console.log('headers', headers);
    // console.log('body', body);

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: headers,
            body: body,
        })
            .then(response => {     
                //throw new Error(response.status);           
                if (response.status === 200) {
                    return response.json()
                } 
                throw new Error(response.status);
            })
            .then(data => {
                resolve(data);
            })
            .catch((error) => {
                console.log('POST error', error);
                reject(error);
            });
    });
}

export function makeGetRequest(url, accessToken) {
    // console.log(accessToken);

    const headers = new Headers();
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Content-Type', 'application/json');

    if (accessToken) {
        headers.append('Authorization', accessToken);
    }

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: headers //{'Authorization': accessToken},
        })
            .then(response => {     
                //throw new Error(response.status);           
                if (response.status === 200) {
                    return response.json()
                } 
                throw new Error(response.status);
            })
            .then(data => {
                //console.log('post data', data);
                resolve(data);
            })
            .catch((error) => {
                console.log('GET error', error);
                reject(error);
            });
    });
}

